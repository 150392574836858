import { sync } from 'vuex-pathify';
import multiselect from 'vue-multiselect';

export default {
  components: {
    multiselect,
  },

  data() {
    return {
      visible: true,
      selected: null,
      results: [],
      isLoading: false,
    };
  },

  computed: {
    ...sync([
      'webamp/playlist', //
      'webamp/artist',
      'webamp/album',
      'webamp/medium',
      'webamp/genre',
      'webamp/skin',
    ]),
  },

  mounted() {
    Bus.$on('searches:clear', (except) => {
      if (except !== this.type) {
        this.selected = null;
      }
    });

    Bus.$on('searches:hide-others', (except) => {
      if (except !== this.type) {
        this.visible = false;
      }
    });

    Bus.$on('searches:show-others', () => {
      this.visible = true;
    });
  },

  beforeDestroy() {
    Bus.$off('searches:clear');
    Bus.$off('searches:hide-others');
    Bus.$off('searches:show-others');
  },

  methods: {
    hideOthers() {
      Bus.$emit('searches:hide-others', this.type);
    },
    showOthers() {
      Bus.$emit('searches:show-others');
    },
  },
};
